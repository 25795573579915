import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/auth.context";
import authService from "../../services/auth.service";
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import axios from 'axios';
import { useGoogleLogin } from '@react-oauth/google';
import { LanguageContext } from '../../context/language.context';

function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [showPassword, setShowPassword] = useState(false);
  const { strings } = useContext(LanguageContext);

  const navigate = useNavigate();

  const { storeToken, authenticateUser } = useContext(AuthContext);

  const handleEmail = (e) => setEmail(e.target.value);
  const handlePassword = (e) => setPassword(e.target.value);

  const handleLoginSubmit = (e) => {
    e.preventDefault();
    const requestBody = { email, password };
  
    // Send a request to the server using a service
    authService
      .login(requestBody)
      .then((response) => {
        if (response.data.needsVerification) {
          // Navigate to verification page
          navigate('/verify', { state: { email } });
        } else {
          storeToken(response.data.accessToken, response.data.refreshToken);
          authenticateUser();
          navigate("/mycourses");
        }
      })
      .catch((error) => {
        let errorDescription = "Ocorreu um erro";
  
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          errorDescription = error.response.data.message || errorDescription;
        } else if (error.request) {
          // The request was made but no response was received
          errorDescription = "Sem resposta do servidor";
        } else {
          // Something happened in setting up the request that triggered an Error
          errorDescription = error.message || errorDescription;
        }
  
        setErrorMessage(errorDescription);
      });
  };

  //Create an account with google
  const signInGoogle = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        const res = await axios.get("https://www.googleapis.com/oauth2/v3/userinfo", {
          headers: {
            Authorization: `Bearer ${tokenResponse.access_token}`,
          },
        });

        const requestBody = { email: res.data.email, googleCredential: res.data.sub, firstName: res.data.given_name, lastName: res.data.family_name, profilePicture: res.data.picture };

        // Send a request to the server using a service
        authService
          .googleAuth(requestBody)
          .then((response) => {
            storeToken(response.data.accessToken, response.data.refreshToken);
            authenticateUser();
            // If the POST request is successful, redirect to the main page
            navigate("/mycourses");
          })
          .catch((error) => {
            // If the request resolves with an error, set the error message in the state
            const errorDescription = error.response.data.message;
            setErrorMessage(errorDescription);
          });
      } catch (error) {
        console.log(error);
      }
    }
  });

  return (
    <div className="items-center justify-start containerNavbar">
      <div className="flex flex-col justify-center p-6 lg:p-8 bg-[#FFFFFF1A] lg:w-1/2 rounded-[16px] sm:mt-[5%] border border-[#A892FF33] max-w-[392px] lg:max-w-none w-full">
        <h2 className="text-center text-white text-[20px] sm:text-[24px] leading-7 font-bold mb-7">Já estuda na Dionamite Academy?</h2>
        <div className="relative flex flex-row">
          <button onClick={() => signInGoogle()} className="flex items-center justify-center w-full p-2 text-[12px] sm:text-sm font-bold text-primary bg-white rounded pl-9">
            <img src="/google.png" alt="Google Logo" className="absolute w-4 sm:w-5 left-3" />
            {strings.login.loginGoogle}
          </button>
        </div>
        <div className="flex items-center my-3">
          <div className="h-[1px] w-1/2 bg-primary mt-1" /> <span className="p-4 text-sm font-bold text-primary">ou</span> <div className="h-[1px] w-1/2 bg-primary mt-1" />
        </div>
        <form onSubmit={handleLoginSubmit} className="flex flex-col gap-4">
          <input className="w-full px-4 h-12 text-xs font-normal tracking-[1px] border border-[#6753B41A] rounded outline-none bg-[#CFC4F814] text-secondary placeholder-inherit" type="email" name="email" placeholder="Email" value={email} onChange={handleEmail} />
          <div>
            <div className="relative flex">
              <input
                className="w-full px-4 h-12 pr-10 text-xs font-normal tracking-[1px] border border-[#6753B41A] rounded outline-none bg-[#CFC4F814] text-secondary placeholder-inherit"
                type={showPassword ? "text" : "password"}
                name="password"
                placeholder={strings.login.password}
                value={password}
                onChange={handlePassword}
              />
              <button
                className="absolute right-0 h-12 px-3 py-3 transform -translate-y-1/2 top-1/2"
                type="button"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <FaEyeSlash className="text-secondary" /> : <FaEye className="text-secondary" />}
              </button>
            </div>
            <div onClick={() => navigate("/forgotPassword")} className="mt-4 text-xs cursor-pointer text-primary w-fit">
              {strings.login.forgotPassword}
            </div>
          </div>
          {errorMessage && <p className="text-sm text-red-500">{errorMessage}</p>}
          <button type="submit" className="hover:bg-primary-hover rounded transition duration-500 ease-in-out bg-primary text-[11px] sm:text-[12px] uppercase font-bold py-3 px-6 text-white hover:text-textHover tracking-[2px]">{strings.login.login}</button>
        </form>
        <div className="flex items-center justify-center mt-6 text-secondary">
          <p className="pr-1 sm:pr-2 text-[12px] font-light sm:text-sm text-nowrap">{strings.login.noAccount}</p>
          <Link className="font-bold text-[12px] transition-all sm:text-sm hover:underline text-nowrap" to={"/signup"}>{strings.login.signup}</Link>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;