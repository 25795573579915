import React, { useState, useEffect, useRef } from 'react';
import * as Progress from '@radix-ui/react-progress';

const ProgressBar = ({ progress }) => {
    const progressRef = useRef(null);
    const [containerWidth, setContainerWidth] = useState(0);
    // Use a useRef for the start time to persist its value across renders without causing re-renders
    const animationStartTime = useRef(Date.now());
    const [displayedProgress, setDisplayedProgress] = useState(0);

    const updateContainerWidth = () => {
        if (progressRef.current) {
            setContainerWidth(progressRef.current.offsetWidth);
        }
    };

    useEffect(() => {
        updateContainerWidth();
        window.addEventListener('resize', updateContainerWidth);
        return () => window.removeEventListener('resize', updateContainerWidth);
    }, []);

    useEffect(() => {
        // Reset start time for each new progress change
        animationStartTime.current = Date.now();

        const updateFrequency = 15; // Adjust update frequency as needed
        const totalDuration = 2500; // Total duration to match the CSS animation

        const updateProgress = () => {
            const elapsedTime = Date.now() - animationStartTime.current;
            const progressRate = elapsedTime / totalDuration;
            const newDisplayedProgress = progress * progressRate;

            if (newDisplayedProgress < progress && elapsedTime < totalDuration) {
                setDisplayedProgress(newDisplayedProgress);
                requestAnimationFrame(updateProgress);
            } else {
                setDisplayedProgress(progress); // Ensure it ends exactly on the target progress
            }
        };

        requestAnimationFrame(updateProgress);
        // Clean up function to reset displayed progress if the component unmounts early
        return () => setDisplayedProgress(progress);
    }, [progress]);

    const indicatorWidth = 47.5;
    const adjustment = ((progress - 0) * (8) / (100)) - 4;
    const percentagePosition = `${((progress / 100) * containerWidth) - (indicatorWidth + adjustment) + 23}px`;

    return (
        <div className="relative" ref={progressRef}>
            <Progress.Root
                className="overflow-hidden bg-gray-200 border-4 rounded-lg h-9"
                style={{ transform: 'translateZ(0)' }}
                value={progress}
            >
                <Progress.Indicator
                    className="bg-gradient-to-r from-secondary to-primary w-full h-full transition-transform duration-[2500ms] ease-[cubic-bezier(0.65, 0, 0.35, 1)]"
                    style={{ transform: `translateX(-${100 - progress}%)` }}
                />
            </Progress.Root>
            <div className="absolute -top-9 h-7 left-0 transform translate-x-[50%] bg-gradient-to-r text-white font-bold w-12 text-center text-xs bg-primary rounded-lg transition-transform duration-[2500ms] ease-[cubic-bezier(0.65, 0, 0.35, 1)]" style={{ transform: `translateX(${percentagePosition})` }}>
                <div className="w-0 h-0 border-l-[7px] border-l-transparent border-t-[7px] border-t-primary border-r-[7px] left-1/2 transform -translate-x-1/2 border-r-transparent absolute -bottom-[6px]"></div>
                <div className='absolute inset-0 flex items-center justify-center font-bold'>{Math.min(100, displayedProgress.toFixed(0))}%</div>
            </div>
        </div>
    );
};

export default ProgressBar;
