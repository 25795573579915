import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { LanguageContext } from '../../context/language.context';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { AuthContext } from "../../context/auth.context";
import { jwtDecode } from 'jwt-decode';
import ProgressBar from '../../components/ProgressBar/ProgressBar';
import Loading from "../../components/Loading/Loading";

const MyCourses = () => {
    const { user } = useContext(AuthContext);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [courses, setCourses] = useState([]);
    const [progress, setProgress] = useState(0);
    const [categories, setCategories] = useState([]);
    const { strings, language } = useContext(LanguageContext);
    const [filter, setFilter] = useState(queryParams.get('filter') || '');
    const [categoryFilter, setCategoryFilter] = useState(queryParams.get('categoryFilter') || '');
    const [isLoading, setIsLoading] = useState(true);
    const languageMap = {
        PT: 'portuguese',
        EN: 'english',
    };

    const difficultyOptions = {
        beginner: 'Fácil',
        intermediate: 'Médio',
        advanced: 'Difícil',
        'ultra-advanced': 'Ultra-Avançado'
    };

    // Function to map the website language to the value expected in audioOptions
    const getDefaultAudioFilter = () => {
        const urlAudioFilter = queryParams.get('audioFilter');
        if (urlAudioFilter) {
            return urlAudioFilter;
        }
        const defaultAudioValue = languageMap[language];
        return defaultAudioValue;
    };

    const [audioFilter, setAudioFilter] = useState(getDefaultAudioFilter());
    const navigate = useNavigate();

    const token = localStorage.getItem('accessToken');
    const decodedToken = jwtDecode(token);

    useEffect(() => {
        const fetchCourses = async () => {
            try {
                const response = await axios.get(`https://dionamiteacademyapi.com:41278/user/allCourses/${decodedToken._id}`, { headers: { Authorization: `Bearer ${token}` } });
                const coursesData = response.data.courses

                // Calculate progress for each course
                const coursesWithProgress = coursesData.map(course => {
                    // Check if modules exist and is an array
                    const modules = Array.isArray(course.courseId.modules) ? course.courseId.modules : [];

                    // Calculate total lessons
                    const totalLessons = modules.reduce((acc, module) => {
                        const lessons = Array.isArray(module.lessons) ? module.lessons : [];
                        return acc + lessons.length;
                    }, 0);

                    // Calculate completed lessons based on the progress array
                    const completedLessons = Array.isArray(course.progress) ? course.progress.length : 0;

                    // Calculate progress percentage
                    const progressNumber = totalLessons > 0 ? (completedLessons / totalLessons) * 100 : 0;

                    return {
                        ...course,
                        progressNumber,
                    };
                });

                setCourses(coursesWithProgress)
            } catch (error) {
                console.error('Error fetching user:', error);
            } finally {
                setIsLoading(false);
            }
        };

        const fetchCategories = async () => {
            try {
                const response = await axios.get('https://dionamiteacademyapi.com:41278/category/getAll');
                const formattedCategories = response.data.map(cat => ({
                    label: cat.name,
                    value: cat._id
                }));
                setCategories(formattedCategories);
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };

        fetchCategories();
        fetchCourses();

        const updateUrlParamsOnInit = (params) => {
            const urlSearchParams = new URLSearchParams(location.search);
            let shouldUpdate = false;

            // Check and set the audio filter if it's not already in the URL
            if (!urlSearchParams.has('audioFilter') && params.audioFilter) {
                urlSearchParams.set('audioFilter', params.audioFilter);
                shouldUpdate = true;
            }

            // Only navigate if necessary to avoid unnecessary history entries
            if (shouldUpdate) {
                navigate(`?${urlSearchParams.toString()}`, { replace: true });
            }
        };

        // Automatically set the audioFilter in the URL on component load if necessary
        if (!queryParams.get('audioFilter')) {
            updateUrlParamsOnInit({ audioFilter: getDefaultAudioFilter() });
        }
    }, []);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        setFilter(queryParams.get('filter') || '');
        setCategoryFilter(queryParams.get('categoryFilter') || '');
        setAudioFilter(queryParams.get('audioFilter') || '');
    }, [location.search]);

    const handleFilterChange = (e) => {
        setFilter(e.target.value);
        updateUrlParams({ filter: e.target.value });
    };

    const handleAudioChange = (selectedOption) => {
        const newValue = selectedOption ? selectedOption.value : '';
        setAudioFilter(newValue);
        updateUrlParams({ audioFilter: newValue });
    };

    const handleCategoryChange = (selectedOption) => {
        setCategoryFilter(selectedOption ? selectedOption.value : '');
        updateUrlParams({ categoryFilter: selectedOption ? selectedOption.value : '' });
    };

    const updateUrlParams = (params) => {
        const urlSearchParams = new URLSearchParams(location.search);
        Object.entries(params).forEach(([key, value]) => {
            if (value !== '') {
                urlSearchParams.set(key, value);
            } else {
                urlSearchParams.delete(key);
            }
        });
        navigate(`?${urlSearchParams.toString()}`);
    };

    const filteredCourses = courses.filter(course => {
        return (categoryFilter ? course.courseId.categories.some(category => category === categoryFilter) : true) &&
            (audioFilter ? course.courseId.audio === audioFilter : true) &&
            course.courseId.title.toLowerCase().includes(filter.toLowerCase());
    });

    const audioOptions = [
        { label: strings.courses.audioOptions.portuguese, value: 'portuguese' },
        { label: strings.courses.audioOptions.english, value: 'english' }
    ];

    const customStyles = {
        control: (styles) => ({
            ...styles,
            backgroundColor: '#CFC4F814',
            borderColor: '#6753B41A',
            borderWidth: '0px',
            boxShadow: 'none',
            '&:hover': {
                borderColor: '#cbd5e1'
            }
        }),
        placeholder: (styles) => ({
            ...styles,
            color: 'secondary' // Custom placeholder color
        }),
        singleValue: (styles) => ({
            ...styles,
            color: 'secondary' // Custom text color (example: primary)
        }),
        input: (styles) => ({
            ...styles,
            color: 'secondary' // Custom search input text color
        }),
        dropdownIndicator: (styles) => ({
            ...styles,
            color: 'secondary', // Custom color for the dropdown arrow
            '&:hover': {
                color: '#6753B4' // Custom hover color for the dropdown arrow
            }
        }),
        clearIndicator: (styles) => ({
            ...styles,
            color: 'secondary', // Custom color for the clear "X" button
            '&:hover': {
                color: '#6753B4' // Custom hover color for the clear "X" button
            }
        }),
        menu: (styles) => ({
            ...styles,
            borderRadius: '0.25rem',
            boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.1)',
        }),
        option: (styles, { isFocused, isSelected }) => {
            return {
                ...styles,
                backgroundColor: isFocused ? '#ebf8ff' : isSelected ? '#bee3f8' : undefined,
                color: '#1a202c', // Gray-800
                cursor: 'pointer',
                '&:active': {
                    backgroundColor: '#bee3f8',
                },
            };
        },
    };

    const selectedCategoryOption = categories.find(cat => cat.value === categoryFilter);
    const selectedAudioOption = audioOptions.find(option => option.value === audioFilter);

    if (isLoading) {
        return <Loading />;
    }

    return (
        <div className="mb-20 lg:py-8 containerNavbar">
            <h1 className="mb-6 text-2xl font-bold text-white sm:text-4xl">{strings.navbar.options.courses.myCourses}</h1>
            {courses.length !== 0 && (
                <div className='flex flex-col'>
                    <input
                        type="text"
                        placeholder={strings.courses.searchCourses}
                        className="block lg:hidden w-full px-4 mb-6 h-12 text-xs font-normal tracking-[1px] border border-[#6753B41A] rounded outline-none bg-[#CFC4F814] text-secondary placeholder-inherit"
                        value={filter}
                        onChange={handleFilterChange}
                    />
                    <div className='flex flex-col self-end w-full mb-8 space-y-4 sm:space-y-0 lg:w-[45%] sm:space-x-4 sm:flex-row'>
                        <Select
                            options={categories}
                            value={selectedCategoryOption}
                            onChange={handleCategoryChange}
                            className="w-full text-xs text-secondary outline-none bg-[#CFC4F814] border border-[#6753B41A] rounded focus:outline-none placeholder-inherit font-normal tracking-[1px]"
                            styles={customStyles}
                            placeholder={strings.courses.category}
                            isClearable
                        />

                        <Select
                            options={audioOptions}
                            value={selectedAudioOption}
                            onChange={handleAudioChange}
                            className="w-full text-xs text-secondary outline-none bg-[#CFC4F814] border border-[#6753B41A] rounded focus:outline-none placeholder-inherit font-normal tracking-[1px]"
                            styles={customStyles}
                            placeholder={strings.courses.audio}
                            isClearable
                        />
                    </div>
                </div>)}
            {courses.length === 0 ? (
                <div className='text-center'>
                    <img src='/coursesEmptyState.png' className='self-center mx-auto size-56 sm:size-72' />
                    <p className='mb-8 text-white'>Ainda não tem cursos comprados, veja a nossa oferta!</p>
                    <button onClick={() => navigate('/allcourses')} className="px-6 py-4 text-[12px] leading-4 tracking-[2px] uppercase bg-dionamiteOrange rounded text-[#381708] border-[#FF5C00] border font-bold">Ver todos os cursos</button>
                </div>
            ) : filteredCourses.length === 0 ? (
                <div className='text-center'>
                    <p className='mt-4 text-xs text-white sm:text-base'>{strings.courses.noResults}</p>
                </div>
            ) : (
                <div className="w-full">
                    <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-y-10 gap-x-8">
                        {filteredCourses.map(course => (
                            <div onClick={() => navigate(`/mycourses/${course.courseId.slug}`)} key={course.courseId._id} className="flex flex-col bg-[#FFFFFF1A] rounded-[16px] overflow-hidden border border-[#A892FF33] transition duration-500 ease-in-out hover:shadow-card group cursor-pointer">
                                <img src={course?.courseId?.image} alt={`Image of ${course?.courseId?.title}`} className='h-[180px] object-cover' />
                                <div className='flex flex-col justify-between h-full space-y-14'>
                                    <div className='px-4 pt-4'>
                                        <p className='text-secondary font-bold text-[20px] leading-7'>{course?.courseId?.title}</p>
                                        <p className='text-primary font-medium text-[14px] leading-6 mb-4'>{difficultyOptions[course?.courseId?.difficulty]}</p>
                                        <p className='text-white text-[14px] leading-6 font-medium line-clamp-4'>{course?.courseId?.description}</p>
                                    </div>
                                    <div className='flex flex-col p-4 pt-0' >
                                        <ProgressBar progress={course?.progressNumber} />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default MyCourses;
