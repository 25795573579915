import React, { useContext, useState } from 'react';
import CourseInfo from '../../components/CourseInfo/CourseInfo';
import axios from 'axios';
import { AuthContext } from '../../context/auth.context';
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom';
import { GoArrowLeft } from "react-icons/go";

const CreateCoursePage = () => {
    const { user } = useContext(AuthContext)
    const [course, setCourse] = useState({
        title: '',
        slug: '',
        trailerURL: '',
        image: '',
        description: '',
        price: '',
        brPrice: '',
        audio: '',
        type: '',
        categories: [],
        difficulty: '',
        /* access: '', */
        duration: '',
        requirements: '',
        forWhom: ''
    });
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate()

    const handleChange = (input) => (selectedOptions) => {
        if (input === 'categories') {
            setCourse(prevCourse => ({
                ...prevCourse,
                [input]: selectedOptions ? selectedOptions.map(option => ({
                    _id: option.value,
                    name: option.label
                })) : []
            }));
        } else if (selectedOptions && selectedOptions.value !== undefined) {
            setCourse(prevCourse => ({
                ...prevCourse,
                [input]: selectedOptions.value
            }));
        } else if (selectedOptions && selectedOptions.target) {
            setCourse(prevCourse => ({
                ...prevCourse,
                [input]: selectedOptions.target.value
            }));
        }
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        setCourse(prevCourse => ({
            ...prevCourse,
            image: file // Add the image blob to the course details
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (course.title === '' || course.slug === '' || course.description === '' || course.price === '' || course.brPrice === '' || course.audio === '' || course.difficulty === '' || course.type === '' || course.categories.length === 0 || course.access === '' || course.duration === '' || course.requirements === '' || course.forWhom === '' || course.image === '') {
            toast.error('Please fill in all fields');
            return;
        }

        setIsLoading(true);

        const formData = new FormData();
        formData.append('title', course.title);
        formData.append('slug', course.slug);
        if (course.trailerURL) formData.append('trailerURL', course.trailerURL);
        formData.append('description', course.description);
        formData.append('price', course.price);
        formData.append('brPrice', course.brPrice);
        formData.append('audio', course.audio);
        formData.append('type', course.type);
        course.categories.forEach((categoryId, index) => {
            formData.append(`categories[${index}]`, categoryId);
        });
        /* formData.append('access', course.access); */
        formData.append('difficulty', course.difficulty);
        formData.append('duration', course.duration);
        formData.append('requirements', course.requirements);
        formData.append('forWhom', course.forWhom);
        formData.append('image', course.image);
        formData.append('creator', user?._id);

        try {
            await axios.post('https://dionamiteacademyapi.com:41278/course/createCourse', formData, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}`, 'Content-Type': 'multipart/form-data' } });
            toast.success('Course created successfully');
            setCourse({
                title: '',
                slug: '',
                description: '',
                price: '',
                brPrice: '',
                audio: '',
                type: '',
                categories: [],
                access: '',
                duration: '',
                requirements: '',
                forWhom: '',
                image: ''
            });
        } catch (err) {
            toast.error('Error creating course')
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className='mb-20 lg:py-8 containerNavbar'>
            <div className="fixed hidden p-2 bg-white rounded-full lg:block left-[3%] 2xl:left-[6%] cursor-pointer top-32 z-[100]" onClick={() => navigate('/dashboard')}>
                <GoArrowLeft className="text-black size-6" />
            </div>
            <h1 className="mb-6 text-2xl font-bold text-white sm:text-4xl">Criar curso</h1>
            <CourseInfo course={course} handleChange={handleChange} handleImageChange={handleImageChange} />

            <div className="flex items-center justify-center w-full mt-2">
                <button
                    className="hover:bg-primary-hover transition duration-500 ease-in-out rounded w-full sm:w-1/3 xl:w-1/4 mb-6 text-center bg-primary text-[11px] sm:text-[12px] uppercase font-bold py-3 px-6 text-white hover:text-textHover tracking-[2px]"
                    onClick={handleSubmit}
                    disabled={isLoading} // Disable button when loading
                >
                    {isLoading ? 'A criar...' : 'Criar Curso'}
                </button>
            </div>
        </div>
    );
}

export default CreateCoursePage;
