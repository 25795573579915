import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import CourseInfoEdit from '../../components/CourseInfo/CourseInfoEdit';
import Loading from '../../components/Loading/Loading';
import Select from 'react-select';
import { FaTrash } from 'react-icons/fa';
import toast from 'react-hot-toast'
import { GoArrowLeft } from "react-icons/go";

const CourseAdminPage = () => {
    const { courseId } = useParams();
    const location = useLocation();
    const [originalCourse, setOriginalCourse] = useState({});
    const [course, setCourse] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [modules, setModules] = useState([]);
    const [selectedModule, setSelectedModule] = useState('');
    const [currentView, setCurrentView] = useState('courseDetails');
    const [moduleEditName, setModuleEditName] = useState('');
    const navigate = useNavigate()

    useEffect(() => {
        const fetchCourse = async () => {
            try {
                const response = await axios.get(`https://dionamiteacademyapi.com:41278/course/getCourse/${courseId}`);
                setOriginalCourse(response.data);
                setCourse(response.data);
                setSelectedImage(response.data.image);
                const fetchedModules = response.data.modules.length > 0 ? response.data.modules : [];
                setModules(fetchedModules);

                // Set the first module as the selected module
                if (fetchedModules.length > 0) {
                    setSelectedModule({ value: fetchedModules[0]._id, label: fetchedModules[0].name });
                    setModuleEditName(fetchedModules[0].name); // Set the name for module editing
                }

                // Read the current view and module from the URL query parameters
                const query = new URLSearchParams(location.search);
                const view = query.get('view');
                const selectedModuleId = query.get('module');
                if (view) {
                    setCurrentView(view);
                }
                if (selectedModuleId) {
                    const selectedModule = fetchedModules.find(module => module._id === selectedModuleId);
                    if (selectedModule) {
                        setSelectedModule({ value: selectedModule._id, label: selectedModule.name });
                        setModuleEditName(selectedModule.name);
                    }
                }
            } catch (error) {
                console.error('Error fetching course:', error);
            }
        };

        fetchCourse();
    }, [courseId, location.search]);

    const updateQueryParams = (key, value) => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set(key, value);
        navigate({ search: searchParams.toString() });
    };

    const handleNavigate = (lessonId) => {
        navigate(`/lesson-admin/${lessonId}`, { state: { courseId } });
    };

    const handleChange = (input) => (selectedOptions) => {
        if (input === 'categories') {
            setCourse(prevCourse => ({
                ...prevCourse,
                [input]: selectedOptions ? selectedOptions.map(option => ({
                    _id: option.value,
                    name: option.label
                })) : []
            }));
        } else if (selectedOptions && selectedOptions.value !== undefined) {
            setCourse(prevCourse => ({
                ...prevCourse,
                [input]: selectedOptions.value
            }));
        } else if (selectedOptions && selectedOptions.target) {
            setCourse(prevCourse => ({
                ...prevCourse,
                [input]: selectedOptions.target.value
            }));
        }
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        setCourse(prevCourse => ({
            ...prevCourse,
            image: file // Add the image blob to the course details
        }));
    };

    // This function can be used to deeply compare two objects
    const objectsAreSame = (x, y) => {
        const objectsAreSame = JSON.stringify(x) === JSON.stringify(y);
        return objectsAreSame;
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (course.title === '' || course.slug === '' || course.description === '' || course.price === '' || course.brPrice === '' || course.audio === '' || course.difficulty === '' || course.type === '' || course.categories.length === 0 || course.access === '' || course.duration === '' || course.requirements === '' || course.forWhom === '' || course.image === '') {
            toast.error('Please fill in all fields');
            return;
        }

        // Check if the course object has been modified compared to the original
        if (objectsAreSame(originalCourse, course)) {
            toast.error('No changes detected. Please modify the course before saving.');
            return;
        }

        setIsLoading(true);

        const formData = new FormData();
        formData.append('title', course.title);
        formData.append('slug', course.slug);
        if (course.trailerURL) formData.append('trailerURL', course.trailerURL);
        formData.append('description', course.description);
        formData.append('price', course.price);
        formData.append('brPrice', course.brPrice);
        formData.append('audio', course.audio);
        formData.append('type', course.type);
        course.categories.forEach((category) => {
            formData.append('categories', category._id);
        });
        /* formData.append('access', course.access); */
        formData.append('difficulty', course.difficulty);
        formData.append('duration', course.duration);
        formData.append('requirements', course.requirements);
        formData.append('forWhom', course.forWhom);
        formData.append('image', course.image);

        try {
            await axios.patch(`https://dionamiteacademyapi.com:41278/course/editCourse/${courseId}`, formData, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}`, 'Content-Type': 'multipart/form-data' } });
            toast.success('Course edited successfully');
            setOriginalCourse(course);
        } catch (err) {
            console.log(err);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCreateModule = async (event) => {
        event.preventDefault();

        try {
            setIsLoading(true);
            const response = await axios.post('https://dionamiteacademyapi.com:41278/module/createModule', { courseId, name: `Module ${modules.length + 1}` }, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } });

            const newModule = response.data;
            setModules(prevModules => [...prevModules, newModule]);
            setSelectedModule({ value: newModule._id, label: newModule.name });
        } catch (error) {
            console.error('Error creating module:', error);
        } finally {
            setIsLoading(false);
        }
    }

    const handleAddLesson = async (event) => {
        event.preventDefault();

        try {
            setIsLoading(true);
            const response = await axios.post('https://dionamiteacademyapi.com:41278/lesson/createLesson', { course: courseId, module: selectedModule.value, name: `Lesson ${modules.find(module => module._id === selectedModule.value).lessons.length + 1}` }, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } });

            const newLesson = response.data;
            setModules(prevModules => prevModules.map(module => module._id === selectedModule.value ? { ...module, lessons: [...module.lessons, newLesson] } : module));
        } catch (error) {
            console.error('Error creating lesson:', error);
        } finally {
            setIsLoading(false);
        }
    }

    const handleModuleSelect = module => {
        setSelectedModule(module);
        setModuleEditName(module.label); // Set initial value for module name edit
    };

    const handleModuleNameChange = (event) => {
        setModuleEditName(event.target.value);
    };

    const handleModuleNameSubmit = async (moduleId) => {
        // Find the original module from your state or context
        const originalModule = modules.find(module => module._id === moduleId);

        // Check if the module name has been changed
        if (originalModule && originalModule.name === moduleEditName) {
            toast.error("Module name has not changed.");
            return;
        }

        try {
            const response = await axios.patch(`https://dionamiteacademyapi.com:41278/module/updateModule/${moduleId}`, { name: moduleEditName }, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } });

            const updatedModule = response.data;
            setModules(prevModules => prevModules.map(module => module._id === updatedModule._id ? { ...updatedModule, lessons: module.lessons } : module));
            setSelectedModule({ value: updatedModule._id, label: updatedModule.name });
        } catch (error) {
            console.error('Error updating module name:', error);
        }
    };

    const handleRemoveModule = async (moduleId) => {
        if (window.confirm("Are you sure you want to remove this module?")) {
            try {
                const response = await axios.delete(`https://dionamiteacademyapi.com:41278/module/deleteModule/${moduleId}`, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } });

                const deletedModule = response.data;
                setModules(prevModules => prevModules.filter(module => module._id !== deletedModule._id));
                setSelectedModule('');
            } catch (error) {
                console.error('Error deleting module:', error);
            }
        }
    };

    const handleRemoveLesson = async (lessonId) => {
        if (window.confirm("Are you sure you want to remove this lesson?")) {
            try {
                const response = await axios.delete(`https://dionamiteacademyapi.com:41278/lesson/deleteLesson/${lessonId}`, { headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` } });

                const deletedLesson = response.data;
                setModules(prevModules => prevModules.map(module => module._id === selectedModule.value ? { ...module, lessons: module.lessons.filter(lesson => lesson._id !== deletedLesson._id) } : module));
            } catch (error) {
                console.error('Error deleting lesson:', error);
            }
        }
    };

    const changeStatus = async () => {
        const confirmMessage = course.status === "inactive" ? "Tem a certeza que quer ativar este curso?" : "Tem a certeza que quer desativar este curso?";

        if (window.confirm(confirmMessage)) {
            try {
                const response = await axios.patch(
                    `https://dionamiteacademyapi.com:41278/course/changeStatus/${courseId}`,
                    {},
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                            'Content-Type': 'application/json'
                        }
                    }
                );

                const newStatus = response.data.course.status;
                setCourse(prevCourse => ({
                    ...prevCourse,
                    status: newStatus
                }));

                const successMessage = newStatus === 'active' ? 'Curso ativado com sucesso!' : 'Curso desativado com sucesso!';
                toast.success(successMessage);
            } catch (error) {
                console.error('Error changing course status:', error);
                toast.error('Erro ao mudar o status do curso.');
            }
        }
    };

    const customStyles = {
        control: (styles) => ({
            ...styles,
            backgroundColor: '#CFC4F814',
            height: '48px',
            borderColor: '#6753B41A',
            borderWidth: '0px',
            boxShadow: 'none',
            '&:hover': {
                borderColor: '#cbd5e1'
            }
        }),
        placeholder: (styles) => ({
            ...styles,
            color: 'secondary', // Custom placeholder color
            padding: '0px 4px'
        }),
        singleValue: (styles) => ({
            ...styles,
            color: 'secondary', // Custom text color (example: primary)
            padding: '0px 4px'
        }),
        input: (styles) => ({
            ...styles,
            color: 'secondary', // Custom search input text color
            padding: '0px 4px'
        }),
        dropdownIndicator: (styles) => ({
            ...styles,
            color: 'secondary', // Custom color for the dropdown arrow
            '&:hover': {
                color: '#6753B4' // Custom hover color for the dropdown arrow
            }
        }),
        clearIndicator: (styles) => ({
            ...styles,
            color: 'secondary', // Custom color for the clear "X" button
            '&:hover': {
                color: '#6753B4' // Custom hover color for the clear "X" button
            }
        }),
        menu: (styles) => ({
            ...styles,
            borderRadius: '0.25rem',
            boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.1)',
        }),
        option: (styles, { isFocused, isSelected }) => {
            return {
                ...styles,
                backgroundColor: isFocused ? '#ebf8ff' : isSelected ? '#bee3f8' : undefined,
                color: '#1a202c', // Gray-800
                cursor: 'pointer',
                '&:active': {
                    backgroundColor: '#bee3f8',
                },
            };
        },
        multiValue: (styles) => ({
            ...styles,
            backgroundColor: '#6753B41A', // Background color of selected items
            borderRadius: '0.25rem',
            display: 'flex',
            alignItems: 'center',
        }),
        multiValueLabel: (styles) => ({
            ...styles,
            color: 'secondary', // Text color of selected items
            padding: '3px 6px',
        }),
        multiValueRemove: (styles) => ({
            ...styles,
            color: 'secondary', // Color of the remove icon
            padding: '3px',
            '&:hover': {
                backgroundColor: '#1a202c',
                color: '#6753B4' // Hover color of the remove icon
            }
        })
    };

    if (!course) {
        return <Loading />;
    }

    return (
        <div className='lg:py-8 containerNavbar'>
            <div className="fixed hidden p-2 bg-white rounded-full lg:block left-[3%] 2xl:left-[6%] cursor-pointer top-32 z-[100]" onClick={() => navigate('/courses-admin?statusFilter=active')}>
                <GoArrowLeft className="text-black size-6" />
            </div>
            <div className="flex justify-center mb-8">
                <div>
                    <button
                        className={`px-5 py-3 text-xs uppercase font-bold transition duration-500 ease-in-out ${currentView === 'courseDetails' ? 'bg-primary-hover text-textHover' : 'text-white bg-gray-800 hover:bg-gray-900'} rounded-l`}
                        onClick={() => { setCurrentView('courseDetails'); updateQueryParams('view', 'courseDetails') }}
                    >
                        Detalhes do curso
                    </button>
                    <button
                        className={`px-5 py-3 text-xs uppercase font-bold transition duration-500 ease-in-out ${currentView === 'modules' ? 'bg-primary-hover text-textHover' : 'text-white bg-gray-800 hover:bg-gray-900'} rounded-r`}
                        onClick={() => { setCurrentView('modules'); updateQueryParams('view', 'modules') }}
                    >
                        Módulos
                    </button>
                </div>
            </div>

            {currentView === 'courseDetails' && (
                <div>
                    <div className="flex flex-col items-center justify-center w-full">
                        <button
                            className="hover:bg-primary-hover transition duration-500 ease-in-out rounded mb-6 text-center bg-primary text-[10px] sm:text-[11px] uppercase font-bold py-3 px-6 text-white hover:text-textHover tracking-[2px]"
                            onClick={changeStatus}
                            disabled={isLoading}
                        >
                            {course.status === 'inactive' ? 'Ativar Curso' : 'Desativar Curso'}
                        </button>
                    </div>

                    <CourseInfoEdit course={course} handleChange={handleChange} handleImageChange={handleImageChange} selectedImageOld={selectedImage} />

                    <div className="flex flex-col items-center justify-center w-full my-4">
                        <button
                            className="hover:bg-primary-hover transition duration-500 ease-in-out rounded w-full sm:w-1/3 xl:w-1/4 mb-6 text-center bg-primary text-[11px] sm:text-[12px] uppercase font-bold py-3 px-6 text-white hover:text-textHover tracking-[2px]"
                            onClick={handleSubmit}
                            disabled={isLoading}
                        >
                            {isLoading ? 'A editar...' : 'Editar Curso'}
                        </button>
                    </div>
                </div>
            )}

            {currentView === 'modules' && (
                <div className="flex flex-col items-center self-center justify-center w-full lg:w-3/4">
                    {modules.length > 0 ?
                        <div className="flex flex-col items-center justify-center w-full">
                            <button
                                className="hover:bg-primary-hover transition duration-500 ease-in-out rounded mb-8 text-center bg-primary text-[10px] sm:text-[11px] uppercase font-bold py-3 px-6 text-white hover:text-textHover tracking-[2px]"
                                onClick={handleCreateModule}
                                disabled={isLoading}
                            >
                                {isLoading ? 'A criar...' : 'Criar Módulo'}
                            </button>
                            <Select
                                className="w-full text-sm mb-4 font-normal tracking-[1px] border border-[#6753B41A] rounded outline-none text-secondary placeholder-gray-400 focus:outline-primary"
                                value={selectedModule}
                                onChange={module => handleModuleSelect(module)}
                                options={modules.map(module => ({ value: module._id, label: module.name }))}
                                placeholder="Select a Module"
                                styles={customStyles}
                            />

                            {selectedModule && modules.find(module => module._id === selectedModule.value) && (
                                <div className="w-full p-4 my-4 bg-[#FFFFFF1A] rounded-[16px] border border-[#A892FF33]">
                                    <div className="flex flex-col items-center justify-center mb-4 space-y-2 md:space-y-0 md:flex-row">
                                        <input
                                            type="text"
                                            value={moduleEditName}
                                            onChange={handleModuleNameChange}
                                            className="w-full md:w-1/3 px-4 h-[42px] text-xs font-normal tracking-[1px] border border-[#6753B41A] rounded outline-none bg-[#CFC4F814] text-secondary placeholder-gray-400 focus:outline-primary"
                                        />
                                        <div className='flex '>
                                            <button
                                                onClick={() => handleModuleNameSubmit(selectedModule.value)}
                                                className="hover:bg-primary-hover md:ml-2 transition duration-500 ease-in-out rounded text-center bg-primary text-[11px] sm:text-[12px] uppercase font-bold py-3 px-5 text-white hover:text-textHover tracking-[1px]"
                                            >
                                                Alterar Nome
                                            </button>
                                            <button
                                                onClick={() => handleRemoveModule(selectedModule.value)}
                                                className="hover:bg-red-700 ml-2 transition duration-500 ease-in-out rounded text-center bg-red-500 text-[11px] sm:text-[12px] uppercase font-bold py-3 px-5 text-white tracking-[1px]"
                                            >
                                                Remover Módulo
                                            </button>
                                        </div>
                                    </div>
                                    <div>
                                        <h3 className="mb-3 text-lg font-bold text-white">Lições:</h3>
                                        {
                                            (() => {
                                                const selectedModuleObj = modules.find(module => module._id === selectedModule.value);
                                                if (!selectedModuleObj) return <p className='text-white'>Módulo não encontrado.</p>;

                                                return selectedModuleObj.lessons && selectedModuleObj.lessons.length > 0 ? (
                                                    <div>
                                                        {selectedModuleObj.lessons.map(lesson => (
                                                            <div key={lesson._id} className="flex items-center justify-between py-1 pl-4 pr-2 mb-2 rounded bg-primary-hover">
                                                                <span
                                                                    onClick={() => handleNavigate(lesson._id)}
                                                                    className="text-sm font-semibold cursor-pointer text-textHover hover:text-gray-950"
                                                                >
                                                                    {lesson.name}
                                                                </span>
                                                                <button
                                                                    onClick={() => handleRemoveLesson(lesson._id)}
                                                                    className="flex items-center p-2 text-red-500 transition duration-500 ease-in-out rounded hover:bg-red-500 hover:text-white"
                                                                >
                                                                    <FaTrash />
                                                                </button>
                                                            </div>
                                                        ))}
                                                    </div>
                                                ) : (
                                                    <p className='text-white'>Sem lições disponiveis neste módulo.</p>
                                                );
                                            })()
                                        }

                                        <button onClick={handleAddLesson} className="hover:bg-primary-hover transition duration-500 ease-in-out rounded mt-4 text-center bg-primary text-[11px] sm:text-[12px] uppercase font-bold py-3 px-6 text-white hover:text-textHover tracking-[2px]">Add Lesson</button>
                                    </div>
                                </div>
                            )}
                        </div>
                        :
                        <div className="flex flex-col items-center justify-center w-full my-4">
                            <p className="mb-10 text-lg font-bold text-white">Módulos não encontrados</p>
                            <button
                                className="hover:bg-primary-hover transition duration-500 ease-in-out rounded text-center bg-primary text-[11px] sm:text-[12px] uppercase font-bold py-3 px-6 text-white hover:text-textHover tracking-[2px]"
                                onClick={handleCreateModule}
                                disabled={isLoading}
                            >
                                {isLoading ? 'A criar...' : 'Criar Módulo'}
                            </button>
                        </div>}

                </div>
            )}


        </div>
    );
};

export default CourseAdminPage;