import React, { useState, useContext } from 'react';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { LanguageContext } from '../../context/language.context';
import axios from 'axios';

const CheckoutForm = (props) => {
  const { amount, currency } = props
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { strings } = useContext(LanguageContext);
  const stripe = useStripe();
  const elements = useElements();

  const formatPrice = (price) => {
    const formattedPrice = new Intl.NumberFormat('pt-PT', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(price);
    return `€${formattedPrice.replace('€', '').trim()}`;
  };

  const formatPriceBR = (price) => {
    const formattedPrice = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(price);
    return `R$${formattedPrice.replace('R$', '').trim()}`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/completion`, // `${window.location.origin}/checkout/${courseId}`,
      },
    });

    if (error) {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <div className='w-full text-center'>
        <button type="submit" disabled={isLoading} className='hover:bg-primary-hover rounded transition duration-500 ease-in-out bg-primary text-[12px] sm:text-[13px] uppercase font-bold py-3 px-6 text-white hover:text-textHover tracking-[2px] my-6'>
          {isLoading ? 'Loading...' : (strings.purchase.pay + ' ' + (currency === 'brl' ? formatPriceBR(amount) : formatPrice(amount)))}
        </button>
      </div>
    </form>
  );
};

export default CheckoutForm;
