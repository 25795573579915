import "./LessonPreviewPage.css"
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { LanguageContext } from '../../context/language.context';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from "../../context/auth.context";
import { GoArrowLeft } from "react-icons/go";

const LessonPreviewPage = () => {
    const { lessonId, courseId } = useParams();
    const { user } = useContext(AuthContext);
    const [lesson, setLesson] = useState(null);
    const [userData, setUserData] = useState(null);
    const [courseData, setCourseData] = useState(null)
    const [headings, setHeadings] = useState([]);
    const { strings } = useContext(LanguageContext);
    const navigate = useNavigate();
    const [updatedContent, setUpdatedContent] = useState(null)
    const [activeHeadingId, setActiveHeadingId] = useState(null);
    const [moduleName, setModuleName] = useState('');
    const [moduleIndex, setModuleIndex] = useState(null);

    const token = localStorage.getItem('accessToken');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [responseUser, responseLesson, responseCourse] = await Promise.all([
                    axios.get(`https://dionamiteacademyapi.com:41278/user/getUser/${user?._id}`, { headers: { Authorization: `Bearer ${token}` } }),
                    axios.get(`https://dionamiteacademyapi.com:41278/lesson/getLessonWithVideos/${lessonId}`, { headers: { Authorization: `Bearer ${token}` } }),
                    axios.get(`https://dionamiteacademyapi.com:41278/course/getCourse/${courseId}`, { headers: { Authorization: `Bearer ${token}` } })
                ]);

                setUserData(responseUser.data);
                setLesson(responseLesson.data);
                setCourseData(responseCourse.data);

                if (responseCourse.data && lessonId) {
                    const module = responseCourse.data.modules.find((module, index) => {
                        if (module.lessons.some(lesson => lesson._id === lessonId)) {
                            setModuleIndex(index + 1);
                            return true;
                        }
                        return false;
                    });
                    if (module) {
                        setModuleName(module.name);
                    }
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [lessonId, courseId, user?._id]);

    useEffect(() => {
        if (lesson?.content) {
            const processedContent = extractHeadings(lesson.content);
            setUpdatedContent(processedContent.updatedContent);
            setHeadings(processedContent.headings);
        }
    }, [lesson]);

    // Function to extract headings and add IDs to the HTML content
    const extractHeadings = (htmlContent) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, 'text/html');
        const headings = Array.from(doc.querySelectorAll('h2')).map((h2, index) => {
            const id = `heading-${index}`;
            h2.id = id;
            // Replace any colons at the end of the heading text
            const text = h2.textContent.replace(/:$/, '').trim();
            return {
                text: text,
                id: id,
            };
        });
        return {
            updatedContent: doc.body.innerHTML,
            headings: headings,
        };
    };

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setActiveHeadingId(entry.target.id);
                }
            });
        }, { rootMargin: "0px 0px -85% 0px" });

        if (headings.length > 0) {
            headings.forEach(heading => {
                const element = document.getElementById(heading.id);
                if (element) observer.observe(element);
            });
        }

        const handleScroll = () => {
            if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
                // Scrolled to the bottom
                setActiveHeadingId(headings[headings.length - 1]?.id);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            observer.disconnect();
            window.removeEventListener('scroll', handleScroll);
        };
    }, [headings]);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const scrollToBottom = () => {
        window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
    };

    const completeLesson = async () => {
        try {
            await axios.patch(`https://dionamiteacademyapi.com:41278/lesson/completeLesson/${user?._id}/${lesson?.course}/${lessonId}`);
            navigate(`/mycourses/${lesson?.course}`);
        } catch (error) {
            console.error('Error fetching user:', error);
        }
    }

    const course = userData?.courses.find(course => course.courseId === lesson?.course);
    const lessonProgress = course ? course.progress.find(lesson => lesson.lessonId === lessonId) : null;
    const isLessonCompleted = lessonProgress ? lessonProgress.watched : false;

    return (
        <div className="w-3/4 py-8 mx-auto mb-12 containerNavbar">
            {lesson && (
                <div className='relative flex flex-wrap justify-between'>
                    <div className="fixed hidden p-2 bg-white rounded-full lg:block left-[3%] 2xl:left-[6%] cursor-pointer top-32 z-[100]" onClick={() => navigate(`/lesson-admin/${lessonId}`, { state: { courseId } })}>
                        <GoArrowLeft className="text-black size-6" />
                    </div>
                    <div className='w-full lg:w-3/4'>
                        <h2 className="mb-6 text-xl font-bold text-secondary">{moduleIndex ? `M${moduleIndex}: ${moduleName}` : moduleName}</h2>
                        <h1 className="text-4xl font-bold text-white mb-14">{lesson?.name}</h1>
                        <div className="lesson-content" dangerouslySetInnerHTML={{ __html: updatedContent }} />
                        {isLessonCompleted ? (
                            <div className="mt-20 text-base text-dionamiteOrange text-end">
                                Esta aula já foi marcada como completa.
                            </div>
                        ) : (
                            <div className="flex justify-end mt-20">
                                <button
                                    onClick={completeLesson}
                                    className=" px-6 py-4 text-[10px] sm:text-[12px] leading-4 tracking-[2px] uppercase bg-dionamiteOrange rounded text-[#381708] border-[#FF5C00] border font-bold "
                                >
                                    Marcar aula como completa
                                </button>
                            </div>
                        )}
                    </div>
                    {/* Positioning container for sidebar */}
                    <div className='relative right-0 hidden w-full mt-14 lg:fixed lg:flex lg:w-[27%]'>
                        {/* Sidebar content */}
                        <div className='h-screen overflow-y-auto'>
                            <nav>
                                {headings?.map(heading => (
                                    <a
                                        key={heading.id}
                                        href={`#${heading.id}`}
                                        className={`block my-3 text-sm cursor-pointer hover:text-gray-300 ${heading.id === activeHeadingId ? 'text-primary' : 'text-white'}`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            const headerOffset = 110;
                                            const elementPosition = document.getElementById(heading.id).getBoundingClientRect().top;
                                            const offsetPosition = elementPosition + window.scrollY - headerOffset;

                                            window.scrollTo({
                                                top: offsetPosition,
                                                behavior: 'smooth'
                                            });
                                        }}
                                    >
                                        {heading.text}
                                    </a>
                                ))}
                                <span className="block mt-10 text-sm text-white cursor-pointer hover:text-gray-300" onClick={scrollToTop}>Back to top</span>
                                <span className="block mt-3 text-sm text-white cursor-pointer mt-30 hover:text-gray-300" onClick={scrollToBottom}>Go to bottom</span>
                            </nav>
                        </div>
                    </div>
                </div>
            )}
        </div>

    );
}

export default LessonPreviewPage;