import React from 'react';
import ReactPlayer from 'react-player';

const VideoPlayer = ({ url }) => {
  const handleContextMenu = (e) => {
    e.preventDefault();
  };

  return (
    <div onContextMenu={handleContextMenu}>
      <ReactPlayer
        url={url}
        controls
        width="100%"
        height="100%"
        config={{
          file: {
            attributes: {
              controlsList: 'nodownload', // disable download button
              disablePictureInPicture: true // disable picture in picture
            }
          }
        }}
      />
    </div>
  );
};

export default VideoPlayer;
