import React from 'react'

function BootcampsPage() {
    return (
        <div className='w-full flex justify-center items-center h-[100vh] flex-col'>
            <div className='flex flex-col w-[80%] sm:w-[460px] text-center'>
                <img className='w-full' src="/bootcamps-empty-state.webp" alt="" />
                <h1 className='mb-[10px] font-bold text-[32px] leading-[40px] gradient-hero'>Em breve abrimos turmas!</h1>
                <p className='mb-[60px] text-[#BFBFBF] text-[16px] leading-6'>O nosso compromisso é com a capacitação de novas habilidades - as turmas bootcamp ajudam imenso a dar um rumo ao estudante que pretende transitar para a carreira em tech.</p>
            </div>
        </div>
    )
}

export default BootcampsPage