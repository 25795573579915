import "./App.css";
import { Routes, Route, useLocation } from "react-router-dom";
import { Toaster } from 'react-hot-toast';

import CookieBanner from "./components/CookieBanner/CookieBanner";
import Footer from "./components/Footer/Footer";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";

import _404Page from "./pages/404Page/404Page";
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import BootcampsPage from "./pages/BootcampsPage/BootcampsPage";
import HomePage from "./pages/HomePage/HomePage";
import ProfilePage from "./pages/ProfilePage/ProfilePage";
import SignupPage from "./pages/SignupPage/SignupPage";
import LoginPage from "./pages/LoginPage/LoginPage";
import MyCoursesPage from "./pages/MyCoursesPage/MyCoursesPage";
import CoursePage from "./pages/CoursePage/CoursePage";
import AllCoursesPage from "./pages/AllCoursesPage/AllCoursesPage";
import DashboardPage from "./pages/Dashboard/DashboardPage";
import CreateCoursePage from "./pages/Dashboard/CreateCoursePage";
import CategoriesPage from "./pages/Dashboard/CategoriesPage";
import CoursesPage from "./pages/Dashboard/CoursesPage";
import CourseAdminPage from "./pages/Dashboard/CourseAdminPage";
import LessonAdminPage from "./pages/Dashboard/LessonAdminPage";
import AdminsPage from "./pages/Dashboard/AdminsPage";
import CheckoutPage from "./pages/CheckoutPage/CheckoutPage";
import Completion from "./pages/CheckoutPage/Completion";
import TeachersPage from "./pages/Dashboard/TeachersPage";
import ResetPasswordPage from "./pages/ResetPasswordPage/ResetPasswordPage";
import MyCourseIndividualPage from "./pages/MyCourseIndividualPage/MyCourseIndividualPage";
import LessonPage from "./pages/LessonPage/LessonPage";
import VerificationPage from "./pages/VerificationPage/VerificationPage";
import CouponsPage from "./pages/Dashboard/CouponsPage";
import LessonPreviewPage from "./pages/Dashboard/LessonPreviewPage";

import Navbar from "./components/Navbar/Navbar";
import IsPrivate from "./components/IsPrivate/IsPrivate";
import IsAnon from "./components/IsAnon/IsAnon";
import IsAdmin from "./components/IsAdmin/IsAdmin";

function App() {
  const location = useLocation();

  // Check if the current pathname matches the dynamic route pattern
  const shouldShowFooter = !/^\/mycourses\/[^/]+\/lesson\/[^/]+$/.test(location.pathname);

  return (
    <div className="flex flex-col min-h-screen App overflow-x-clip">
      <Toaster />
      <Navbar />
      <ScrollToTop />

      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="*" element={<_404Page />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/verify" element={<IsAnon><VerificationPage /></IsAnon>} />

        <Route
          path="/profile"
          element={
            <IsPrivate>
              <ProfilePage />
            </IsPrivate>
          }
        />

        <Route
          path="/checkout/:slug"
          element={
            <IsPrivate>
              <CheckoutPage />
            </IsPrivate>
          }
        />

        <Route
          path="/completion"
          element={
            <IsPrivate>
              <Completion />
            </IsPrivate>
          }
        />

        <Route
          path="/bootcamps"
          element={

            <BootcampsPage />

          }
        />

        <Route
          path="/mycourses"
          element={
            <IsPrivate>
              <MyCoursesPage />
            </IsPrivate>
          }
        />
        <Route
          path="/mycourses/:slug"
          element={
            <IsPrivate>
              <MyCourseIndividualPage />
            </IsPrivate>
          }
        />

        <Route
          path="mycourses/:courseSlug/lesson/:lessonId"
          element={
            <IsPrivate>
              <LessonPage />
            </IsPrivate>
          }
        />

        {/* ADMIN */}
        <Route
          path="/dashboard"
          element={
            <IsPrivate>
              <IsAdmin>
                <DashboardPage />
              </IsAdmin>
            </IsPrivate>
          }
        />

        <Route
          path="/create-course"
          element={
            <IsPrivate>
              <IsAdmin>
                <CreateCoursePage />
              </IsAdmin>
            </IsPrivate>
          }
        />

        <Route
          path="/courses-admin"
          element={
            <IsPrivate>
              <IsAdmin>
                <CoursesPage />
              </IsAdmin>
            </IsPrivate>
          }
        />

        <Route
          path="/courses-admin/:courseId"
          element={
            <IsPrivate>
              <IsAdmin>
                <CourseAdminPage />
              </IsAdmin>
            </IsPrivate>
          }
        />

        <Route
          path="/categories-admin"
          element={
            <IsPrivate>
              <IsAdmin>
                <CategoriesPage />
              </IsAdmin>
            </IsPrivate>
          }
        />

        <Route
          path="/lesson-admin/:lessonId"
          element={
            <IsPrivate>
              <IsAdmin>
                <LessonAdminPage />
              </IsAdmin>
            </IsPrivate>
          }
        />

        <Route
          path="/lesson-preview-admin/:courseId/:lessonId"
          element={
            <IsPrivate>
              <IsAdmin>
                <LessonPreviewPage />
              </IsAdmin>
            </IsPrivate>
          }
        />

        <Route
          path="/manageAdmins"
          element={
            <IsPrivate>
              <IsAdmin>
                <AdminsPage />
              </IsAdmin>
            </IsPrivate>
          }
        />

        <Route
          path="/manageTeachers"
          element={
            <IsPrivate>
              <IsAdmin>
                <TeachersPage />
              </IsAdmin>
            </IsPrivate>
          }
        />

        <Route
          path="/manageCoupons"
          element={
            <IsPrivate>
              <IsAdmin>
                <CouponsPage />
              </IsAdmin>
            </IsPrivate>
          }
        />

        <Route
          path="/course/:slug"
          element={
            <CoursePage />
          }
        />

        <Route
          path="/allcourses"
          element={
            <AllCoursesPage />
          }
        />

        <Route
          path="/signup"
          element={
            <IsAnon>
              <SignupPage />
            </IsAnon>
          }
        />
        <Route
          path="/login"
          element={
            <IsAnon>
              <LoginPage />
            </IsAnon>
          }
        />

        <Route
          path="/forgotPassword"
          element={
            <IsAnon>
              <ResetPasswordPage />
            </IsAnon>
          }
        />        
      </Routes>
      <CookieBanner />
      {shouldShowFooter && <Footer />}
    </div>
  );
}

export default App;
