import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select'
import axios from 'axios';

const CourseInfo = ({ course, handleChange, handleImageChange }) => {
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const fileInputRef = useRef(null);

    const audioOptions = [
        { value: 'portuguese', label: 'Portuguese' },
        { value: 'english', label: 'English' }
    ];

    const typeOptions = [
        { value: 'online', label: 'Online' },
        { value: 'accompanied', label: 'Accompanied' }
    ];

    const difficultyOptions = [
        { value: 'beginner', label: 'Fácil' },
        { value: 'intermediate', label: 'Intermédio' },
        { value: 'advanced', label: 'Avançado' },
        { value: 'ultra-advanced', label: 'Ultra Avançado' }
    ];

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await axios.get('https://dionamiteacademyapi.com:41278/category/getAll');
                const fetchedCategories = response.data.map(cat => ({
                    value: cat._id, // Assuming your categories have an _id field
                    label: cat.name   // Assuming your categories have a name field
                }));
                setCategoryOptions(fetchedCategories);
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };

        fetchCategories();
    }, []);

    const handlePlaceholderClick = () => {
        // Trigger the file input click when the placeholder is clicked
        fileInputRef.current.click();
    };

    const customStyles = {
        control: (styles) => ({
            ...styles,
            backgroundColor: '#CFC4F814',
            height: '48px',
            borderColor: '#6753B41A',
            borderWidth: '0px',
            boxShadow: 'none',
            '&:hover': {
                borderColor: '#cbd5e1'
            }
        }),
        placeholder: (styles) => ({
            ...styles,
            color: '#9ca3af', // Custom placeholder color
            padding: '0px 4px'
        }),
        singleValue: (styles) => ({
            ...styles,
            color: 'secondary', // Custom text color (example: primary)
            padding: '0px 4px'
        }),
        input: (styles) => ({
            ...styles,
            color: 'secondary', // Custom search input text color
            padding: '0px 4px'
        }),
        dropdownIndicator: (styles) => ({
            ...styles,
            color: 'secondary', // Custom color for the dropdown arrow
            '&:hover': {
                color: '#6753B4' // Custom hover color for the dropdown arrow
            }
        }),
        clearIndicator: (styles) => ({
            ...styles,
            color: 'secondary', // Custom color for the clear "X" button
            '&:hover': {
                color: '#6753B4' // Custom hover color for the clear "X" button
            }
        }),
        menu: (styles) => ({
            ...styles,
            borderRadius: '0.25rem',
            boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.1)',
        }),
        option: (styles, { isFocused, isSelected }) => {
            return {
                ...styles,
                backgroundColor: isFocused ? '#ebf8ff' : isSelected ? '#bee3f8' : undefined,
                color: '#1a202c', // Gray-800
                cursor: 'pointer',
                '&:active': {
                    backgroundColor: '#bee3f8',
                },
            };
        },
        multiValue: (styles) => ({
            ...styles,
            backgroundColor: '#6753B41A', // Background color of selected items
            borderRadius: '0.25rem',
            display: 'flex',
            alignItems: 'center',
        }),
        multiValueLabel: (styles) => ({
            ...styles,
            color: 'secondary', // Text color of selected items
            padding: '3px 6px',
        }),
        multiValueRemove: (styles) => ({
            ...styles,
            color: 'secondary', // Color of the remove icon
            padding: '3px',
            '&:hover': {
                backgroundColor: '#1a202c',
                color: '#6753B4' // Hover color of the remove icon
            }
        })
    };

    return (
        <>
            <div className="flex flex-wrap">
                <div className="w-full mb-6 md:pr-10 md:w-3/5 md:mb-0">
                    <div className="mb-6">
                        <label className="block mb-2 text-xs font-bold tracking-wide text-white uppercase" htmlFor="course-title">
                            Course Title
                        </label>
                        <input
                            className="w-full px-4 h-12 text-xs font-normal tracking-[1px] border border-[#6753B41A] rounded outline-none bg-[#CFC4F814] text-secondary placeholder-gray-400 focus:outline-primary"
                            id="course-title"
                            type="text"
                            placeholder="Enter title"
                            value={course.title}
                            onChange={handleChange('title')}
                        />
                    </div>
                    <div className="mb-6">
                        <label className="block mb-2 text-xs font-bold tracking-wide text-white uppercase" htmlFor="course-slug">
                            Course Slug
                        </label>
                        <input
                            className="w-full px-4 h-12 text-xs font-normal tracking-[1px] border border-[#6753B41A] rounded outline-none bg-[#CFC4F814] text-secondary placeholder-gray-400 focus:outline-primary"
                            id="course-slug"
                            type="text"
                            placeholder="Enter slug"
                            value={course.slug}
                            onChange={handleChange('slug')}
                        />
                    </div>
                    <div className="mb-6">
                        <label className="block mb-2 text-xs font-bold tracking-wide text-white uppercase" htmlFor="course-title">
                            Course Trailer
                        </label>
                        <input
                            className="w-full px-4 h-12 text-xs font-normal tracking-[1px] border border-[#6753B41A] rounded outline-none bg-[#CFC4F814] text-secondary placeholder-gray-400 focus:outline-primary"
                            id="course-trailer"
                            type="text"
                            placeholder="Enter trailer url"
                            value={course.trailerURL}
                            onChange={handleChange('trailerURL')}
                        />
                    </div>
                    <div className="flex flex-wrap mb-6">
                        <div className="w-full">
                            <label className="block mb-2 text-xs font-bold tracking-wide text-white uppercase" htmlFor="course-description">
                                Course Description
                            </label>
                            <textarea
                                className="w-full p-4 h-40 text-xs font-normal leading-snug tracking-[1px] border border-[#6753B41A] rounded outline-none bg-[#CFC4F814] text-secondary placeholder-gray-400 appearance-none resize-none focus:outline-primary"
                                id="course-description"
                                placeholder="Enter description"
                                value={course.description}
                                onChange={handleChange('description')}
                            ></textarea>
                        </div>
                    </div>
                    <div className="flex flex-wrap mb-6">
                        <div className="w-full">
                            <label className="block mb-2 text-xs font-bold tracking-wide text-white uppercase" htmlFor="course-requirements">
                                Requirements
                            </label>
                            <textarea
                                className="w-full p-4 h-20 text-xs font-normal leading-snug tracking-[1px] border border-[#6753B41A] rounded outline-none bg-[#CFC4F814] text-secondary placeholder-gray-400 appearance-none resize-none focus:outline-primary"
                                id="course-requirements"
                                placeholder="Enter requirements"
                                value={course.requirements}
                                onChange={handleChange('requirements')}
                            ></textarea>
                        </div>
                    </div>
                    <div className="flex flex-wrap mb-6">
                        <div className="w-full">
                            <label className="block mb-2 text-xs font-bold tracking-wide text-white uppercase" htmlFor="course-forWhom">
                                For Whom
                            </label>
                            <textarea
                                className="w-full p-4 h-20 text-xs font-normal leading-snug tracking-[1px] border border-[#6753B41A] rounded outline-none bg-[#CFC4F814] text-secondary placeholder-gray-400 appearance-none resize-none focus:outline-primary"
                                id="course-forWhom"
                                placeholder="Enter for whom"
                                value={course.forWhom}
                                onChange={handleChange('forWhom')}
                            ></textarea>
                        </div>
                    </div>
                </div>


                <div className="w-full mb-6 md:w-2/5 md:mb-0">

                    <div className="w-full mb-6">
                        <label className="block mb-2 text-xs font-bold tracking-wide text-white uppercase" htmlFor="course-image">
                            Course Image
                        </label>
                        <div className="relative">
                            <input
                                type="file"
                                accept="image/*"
                                id="course-image"
                                onChange={(event) => {
                                    handleImageChange(event);
                                    setSelectedImage(URL.createObjectURL(event.target.files[0])); // Set selectedImage
                                }}
                                className="hidden" // Hide the file input
                                ref={fileInputRef} // Assign the ref to the file input
                            />
                            <div className="mt-2 cursor-pointer" onClick={handlePlaceholderClick}>
                                {selectedImage ? (
                                    <img
                                        src={selectedImage}
                                        alt="Selected Course"
                                        className="mx-auto max-h-32"
                                    />
                                ) : (
                                    <label className="flex flex-col text-center cursor-pointer">
                                        <img
                                            src="/imagePlaceholder.jpg"
                                            alt="Preset Course"
                                            className="mx-auto max-h-32"
                                        />
                                    </label>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-row justify-between mb-6 space-x-6">
                        <div className="w-1/2">
                            <label className="block mb-2 text-xs font-bold tracking-wide text-white uppercase" htmlFor="course-price">
                                Price (EUR)
                            </label>
                            <input
                                className="w-full px-4 h-12 text-xs font-normal tracking-[1px] border border-[#6753B41A] rounded outline-none bg-[#CFC4F814] text-secondary placeholder-gray-400 focus:outline-primary"
                                id="course-price"
                                type="number"
                                placeholder="Enter price in EUR"
                                value={course.price}
                                onChange={handleChange('price')}
                            />
                        </div>

                        <div className="w-1/2">
                            <label className="block mb-2 text-xs font-bold tracking-wide text-white uppercase" htmlFor="course-brPrice">
                                Price (BRL)
                            </label>
                            <input
                                className="w-full px-4 h-12 text-xs font-normal tracking-[1px] border border-[#6753B41A] rounded outline-none bg-[#CFC4F814] text-secondary placeholder-gray-400 focus:outline-primary"
                                id="course-brPrice"
                                type="number"
                                placeholder="Enter price in BRL"
                                value={course.brPrice}
                                onChange={handleChange('brPrice')}
                            />
                        </div>
                    </div>


                    <div className="flex flex-col justify-between lg:space-x-6 lg:mb-6 lg:flex-row">
                        <div className="w-full mb-6 lg:mb-0 lg:w-1/2">
                            <label className="block mb-2 text-xs font-bold tracking-wide text-white uppercase" htmlFor="course-audio">
                                Audio Language
                            </label>
                            <Select
                                className="w-full text-xs font-normal tracking-[1px] border border-[#6753B41A] rounded outline-none  text-secondary placeholder-gray-400 focus:outline-primary"
                                id="course-audio"
                                value={audioOptions.find(option => option.value === course.audio)}
                                onChange={handleChange('audio')}
                                options={audioOptions}
                                styles={customStyles}
                                placeholder="Select Language"
                            />
                        </div>

                        <div className="w-full mb-6 lg:mb-0 lg:w-1/2">
                            <label className="block mb-2 text-xs font-bold tracking-wide text-white uppercase" htmlFor="course-type">
                                Course Type
                            </label>
                            <Select
                                className="w-full text-xs font-normal tracking-[1px] border border-[#6753B41A] rounded outline-none text-secondary placeholder-gray-400 focus:outline-primary"
                                id="course-type"
                                value={typeOptions.find(option => option.value === course.type)}
                                onChange={handleChange('type')}
                                options={typeOptions}
                                styles={customStyles}
                                placeholder="Select Type"
                            />
                        </div>
                    </div>

                    <div className="flex flex-col justify-between lg:space-x-6 lg:mb-6 lg:flex-row">
                        {/* <div className="w-full mb-6 lg:mb-0 lg:w-1/2">
                            <label className="block mb-2 text-xs font-bold tracking-wide text-white uppercase" htmlFor="course-access">
                                Access Type
                            </label>
                            <select
                                className="block w-full px-4 py-3 leading-tight text-gray-700 bg-gray-200 border rounded appearance-none focus:outline-none focus:bg-white"
                                id="course-access"
                                value={course.access}
                                onChange={handleChange('access')}
                            >
                                <option value="">Select Access</option>
                                <option value="unlimited">Unlimited</option>
                                <option value="limited">Limited</option>
                            </select>
                        </div> */}

                        <div className="w-full mb-6 lg:mb-0 lg:w-1/2">
                            <label className="block mb-2 text-xs font-bold tracking-wide text-white uppercase" htmlFor="course-difficulty">
                                Course Difficulty
                            </label>
                            <Select
                                className="w-full text-xs font-normal tracking-[1px] border border-[#6753B41A] rounded outline-none text-secondary placeholder-gray-400 focus:outline-primary"
                                id="course-difficulty"
                                value={difficultyOptions.find(option => option.value === course.difficulty)}
                                onChange={handleChange('difficulty')}
                                options={difficultyOptions}
                                styles={customStyles}
                                placeholder="Select Difficulty"
                            />
                        </div>

                        <div className="w-full mb-6 lg:mb-0 lg:w-1/2">
                            <label className="block mb-2 text-xs font-bold tracking-wide text-white uppercase" htmlFor="course-duration">
                                Duration (hours)
                            </label>
                            <input
                                className="w-full px-4 h-12 text-xs font-normal tracking-[1px] border border-[#6753B41A] rounded outline-none bg-[#CFC4F814] text-secondary placeholder-gray-400 focus:outline-primary"
                                id="course-duration"
                                type="number"
                                placeholder="Enter duration in hours"
                                value={course.duration}
                                onChange={handleChange('duration')}
                            />
                        </div>
                    </div>
                    <div className="w-full mb-6">
                        <label className="block mb-2 text-xs font-bold tracking-wide text-white uppercase" htmlFor="course-categories">
                            Categories
                        </label>
                        <Select
                            isMulti
                            options={categoryOptions}
                            value={course.categories?.map(cat => ({ value: cat._id, label: cat.name }))}
                            onChange={handleChange('categories')}
                            className="w-full text-sm text-secondary outline-none border border-[#6753B41A] rounded focus:outline-none placeholder-gray-400 font-normal tracking-[1px]"
                            styles={customStyles}
                        />
                    </div>
                </div>
            </div>
        </>
    )
};

export default CourseInfo;