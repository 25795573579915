import React, { useContext } from "react";
import { LanguageContext } from "../../context/language.context";

const PrivacyPolicy = () => {
  const { strings } = useContext(LanguageContext);

  return (
    <div className="w-full text-white sm:items-center justify-center lg:py-8 containerNavbar">
      <h1 className="mb-14 font-bold text-[32px] leading-[40px] gradient-hero text-center">
        {strings.privacyPolicy.heading1}
      </h1>
      <div className="w-full">
        <div>{strings.privacyPolicy.subheading1}</div>
        <br />
        <h2 className="text-secondary font-bold text-[30px] leading-7">
          {strings.privacyPolicy.subheading2}
        </h2>
        <br />
        {strings.privacyPolicy.subheadingA}
        <br />
        <div className="mt-1"></div>
        {strings.privacyPolicy.subheading3}
        <br />
        <br />
        <h2 className="text-secondary font-bold text-[30px] leading-7">
          {strings.privacyPolicy.heading2}
        </h2>
        <br />
        {strings.privacyPolicy.subheading4}
        <br />
        <div className="mt-1"></div>
        {strings.privacyPolicy.subheading5}
        <br />
        <br />
        <h2 className="text-secondary font-bold text-[30px] leading-7">
          {strings.privacyPolicy.heading3}
        </h2>
        <br />
        {strings.privacyPolicy.subheading6}
        <br />
        <div className="mt-1"></div>
        {strings.privacyPolicy.subheading7}
        <br />
        <br />
        <h2 className="text-secondary font-bold text-[30px] leading-7">
          {strings.privacyPolicy.heading4}
        </h2>
        <br />
        {strings.privacyPolicy.subheading8}
        <br />
        <br />
        <h2 className="text-secondary font-bold text-[30px] leading-7">
          {strings.privacyPolicy.heading5}
        </h2>
        <br />
        {strings.privacyPolicy.subheading9}
        <br />
        <div className="mt-1"></div>
        {strings.privacyPolicy.subheading10}
        <br />
        <br />
        <h2 className="text-secondary font-bold text-[30px] leading-7">
          {strings.privacyPolicy.heading6}
        </h2>
        <br />
        {strings.privacyPolicy.subheading11}
        <br />
        <div className="mt-1"></div>
        {strings.privacyPolicy.subheading12}
        <br />
        <br />
        <h2 className="text-secondary font-bold text-[30px] leading-7">
          {strings.privacyPolicy.heading7}
        </h2>
        <br />
        {strings.privacyPolicy.subheading13}
        <br />
        <div className="mt-1"></div>
        {strings.privacyPolicy.subheading14}
        <br />
        <br />
        <h2 className="text-secondary font-bold text-[30px] leading-7">
          {strings.privacyPolicy.heading8}
        </h2>
        <br />
        {strings.privacyPolicy.subheading15}
        <br />
        <div className="mt-1"></div>
        {strings.privacyPolicy.subheading16}
        <br />
        <div className="mt-1"></div>
        {strings.privacyPolicy.subheading17}
        <br />
        <br />
        <h2 className="text-secondary font-bold text-[30px] leading-7">
          {strings.privacyPolicy.heading9}
        </h2>
        <br />
        {strings.privacyPolicy.subheading18}
        <br />
        <div className="mt-1"></div>
        {strings.privacyPolicy.subheading19}
        <br />
        <br />
        <h2 className="text-secondary font-bold text-[30px] leading-7">
          {strings.privacyPolicy.heading10}
        </h2>
        <br />
        {strings.privacyPolicy.subheading21}
        <br />
        <br />
        <h2 className="text-secondary font-bold text-[30px] leading-7">
          {strings.privacyPolicy.heading11}
        </h2>
        <br />
        {strings.privacyPolicy.subheading22}
        <br />
        <br />
        <h2 className="text-secondary font-bold text-[30px] leading-7">
          {strings.privacyPolicy.heading12}
        </h2>
        <br />
        {strings.privacyPolicy.subheading23}
        <br />
        <div className="mt-1"></div>
        <div className="ml-4">
          {strings.privacyPolicy.listItems1}
          <br />
          <div className="mt-1"></div>
          {strings.privacyPolicy.listItems2}
          <br />
          <div className="mt-1"></div>
          {strings.privacyPolicy.listItems3}
          <br />
          <div className="mt-1"></div>
          {strings.privacyPolicy.listItems4}
          <br />
          <div className="mt-1"></div>
        </div>
        {strings.privacyPolicy.additionalInfo}
        <br />
        <br />
        <h2 className="text-secondary font-bold text-[30px] leading-7">
          {strings.privacyPolicy.heading13}
        </h2>
        <br />
        {strings.privacyPolicy.subheading24}
        <br />
        <br />
        <h2 className="text-secondary font-bold text-[30px] leading-7">
          {strings.privacyPolicy.heading14}
        </h2>
        <br />
        {strings.privacyPolicy.subheading25}
        <br />
        <br />
        <h2 className="text-secondary font-bold text-[30px] leading-7">
          {strings.privacyPolicy.heading15}
        </h2>
        <br />
        {strings.privacyPolicy.subheading26}
        <br />
        <div className="mt-1"></div>
        <div className="ml-4">
          {strings.privacyPolicy.listItems5}
          <br />
          <div className="mt-1"></div>
          {strings.privacyPolicy.listItems6}
        </div>
        <br />
        <h2 className="text-secondary font-bold text-[30px] leading-7">
          {strings.privacyPolicy.heading16}
        </h2>
        <br />
        {strings.privacyPolicy.subheading27}
        <br />
        <div className="mt-1"></div>
        {strings.privacyPolicy.additionalInfo2}
        <br />
        <br />
        <h2 className="text-secondary font-bold text-[30px] leading-7">
          {strings.privacyPolicy.heading17}
        </h2>
        <br />
        {strings.privacyPolicy.subheading28}
        <div className="ml-4">
          Firefox:{" "}
          <a
            className="underline text-veryLightOrange"
            href={strings.privacyPolicy.browsers.linkFirefox}
          >
            {" "}
            {strings.privacyPolicy.browsers.linkFirefox}
          </a>
          <br />
          <div className="mt-1"></div>
          Chrome:{" "}
          <a
            className="underline text-veryLightOrange"
            href={strings.privacyPolicy.browsers.linkChrome}
          >
            {strings.privacyPolicy.browsers.linkChrome}
          </a>
          <br />
          <div className="mt-1"></div>
          Internet Explorer:{" "}
          <a
            className="underline text-veryLightOrange"
            href={strings.privacyPolicy.browsers.linkIE}
          >
            {strings.privacyPolicy.browsers.linkIE}
          </a>
          <br />
          <div className="mt-1"></div>
          Microsoft Edge:{" "}
          <a
            className="underline text-veryLightOrange"
            href={strings.privacyPolicy.browsers.linkEdge}
          >
            {strings.privacyPolicy.browsers.linkEdge}
          </a>
          <br />
          <div className="mt-1"></div>
          Safari:{" "}
          <a
            className="underline text-veryLightOrange"
            href={strings.privacyPolicy.browsers.linkSafari}
          >
            {strings.privacyPolicy.browsers.linkSafari}
          </a>
          <br />
          <div className="mt-1"></div>
          Opera:{" "}
          <a
            className="underline text-veryLightOrange"
            href={strings.privacyPolicy.browsers.linkOpera}
          >
            {strings.privacyPolicy.browsers.linkOpera}
          </a>
        </div>
        {strings.privacyPolicy.additionalInfo3}
        <br />
        <div className="mt-6" />
        <h2 className="text-orange bg-clip-text leading-[1rem] text-heading5 font-bold">
          {strings.privacyPolicy.subheading20}
        </h2>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
