import axios from "axios";

class AuthService {
  constructor() {
    this.api = axios.create({
      baseURL:
        process.env.REACT_APP_SERVER_URL ||
        "https://dionamiteacademyapi.com:41278",
    });

    // Automatically set JWT token on the request headers for every request
    this.api.interceptors.request.use((config) => {
      const storedToken = localStorage.getItem("accessToken");

      if (storedToken) {
        config.headers = { Authorization: `Bearer ${storedToken}` };
      }

      return config;
    });

    // Intercept responses to handle token refresh
    this.api.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;

        if (error.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
          const refreshToken = localStorage.getItem("refreshToken");

          const skipRoutes = [
            "/auth/login",
            "/auth/verify-code",
            "/auth/refresh-token",
          ];
          if (skipRoutes.some((route) => originalRequest.url.includes(route))) {
            return Promise.reject(error);
          }

          try {
            const response = await this.refreshToken(refreshToken);
            const { accessToken, refreshToken: newRefreshToken } =
              response.data;

            localStorage.setItem("accessToken", accessToken);
            localStorage.setItem("refreshToken", newRefreshToken);

            this.api.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${accessToken}`;
            originalRequest.headers["Authorization"] = `Bearer ${accessToken}`;

            return this.api(originalRequest);
          } catch (err) {
            console.error("Refresh token failed:", err);
            this.logout();
            return Promise.reject(err);
          }
        }

        return Promise.reject(error);
      }
    );
  }

  login = (requestBody) => {
    return this.api.post("/auth/login", requestBody);
    // same as
    // return axios.post("https://dionamiteacademyapi.com:5005/auth/login");
  };

  signup = (requestBody) => {
    return this.api.post("/auth/signup", requestBody);
    // same as
    // return axios.post("https://dionamiteacademyapi.com:5005/auth/singup");
  };

  googleAuth = (requestBody) => {
    return this.api.post("/auth/googleAuth", requestBody);
  };

  verifyCode = (requestBody) => {
    return this.api.post("/auth/verify-code", requestBody);
  };

  verify = () => {
    return this.api.get("/auth/verify");
    // same as
    // return axios.post("https://dionamiteacademyapi.com:5005/auth/verify");
  };

  refreshToken = (refreshToken) => {
    return this.api.post("/auth/refresh-token", { refreshToken });
  };
}

// Create one instance (object) of the service
const authService = new AuthService();

export default authService;
