import { Link } from 'react-router-dom';
import { MdArrowOutward } from 'react-icons/md';

const _404Page = () => {

    return (
        <div className='bg-[#1b1b1b] h-[100vh] transition-all'>
            <div className='flex flex-col justify-center w-full h-full items-center'>
                <div className='font-bold'>
                    <h1 className='text-white sm:text-[12rem] text-[8rem] leading-[1em] shake-text'>404</h1>
                    <p className='text-white text-center'>
                        🧨 UH OH, ALGO CORREU MAL! 🧨
                    </p>
                    <Link to={"/"} className="mt-5 items-center gap-1 hover:text-orange text-veryLightOrange flex justify-center">
                        <MdArrowOutward className="-rotate-90 text-white" />
                        <span className="font-bold uppercase text-white">Vamos voltar!</span>
                    </Link>
                </div>
            </div>
        </div>

    );

};
export default _404Page;